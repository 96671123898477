@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.main-title__word{
    font-size: 180px;
    font-weight: 400;
    color: #000000;
    font-family: "Big Shoulders Text", sans-serif;
    line-height: 160px;
}

.main-title__wordYell{
    font-size: 180px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;
}

.main__para:hover  .cursor2{
    opacity: 0;

}

.discover-btn{
    text-decoration: underline;
}

.GoArrowUpRight{
    font-weight: 700;
    font-size: 16px;
}

.main-img img{
    width: 293px;
    height: 195px;
    animation: rotate 8s  linear infinite;
}
.main-img{
    position: relative;
}

.main-img::before{
    content: "";
    position: absolute;
    width: 40%;
    height: 16px;
    bottom: 0px;
    left: 30%;
    pointer-events: none;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0, rgba(0, 0, 0, 0) 80%);
}

.visit-main__title{
    font-size: 17px;
    font-weight: 700;
    color: #000000;
}

.visit__icon{
    width: 37px;
    height: 37px;
    display: grid;
    place-content: center;
    background-color: #000000;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    /* transition: all 1s ease-in-out; */

}

.visit__icon:hover{
    background-color: #FED21B;
    transition: all .2s ease-in-out;
    color: #000000;
}

@keyframes rotate {
    from{
        transform: rotateY(0deg);
    }
    to{
        transform: rotateY(-360deg);
    }
}


.word__container , .main-title__words{
    position: relative;
}

.main-title__wordYell{
    position: absolute;
    opacity: 0;
    margin-top: -135px;
}

.word{
    position: absolute;
}

.w1{
    animation: ani1 18s infinite;
  }
  
  .w2{
    animation: ani2 18s infinite;
  }
  
  .w3{
    animation: ani3 18s infinite;
  }
  
  .w4{
    animation: ani4 18s infinite;
  }
  
  .w5{
    animation: ani5 18s infinite;
  }

  .w6{
    animation: ani6 18s infinite;
  }
  
  .w7{
    animation: ani7 18s infinite;
  }
  
  .w8{
    animation: ani8 18s infinite;
  }
  
  .w9{
    animation: ani9 18s infinite;
  }
  
  .w10{
    animation: ani10 18s infinite;
  }
  .w11{
    animation: ani11 18s infinite;
  }
  .w12{
    animation: ani12 18s infinite;
  }
  
  @keyframes ani1 {
    0%{
      opacity: 0;
    }
    8%{
      opacity: 1;
    }
    16%{
      opacity: 0;
    }
  }
  
  @keyframes ani2 {
    16%{
      opacity: 0;
    }
    25%{
      opacity: 1;
    }
    33%{
      opacity: 0;
    }
  }
  
  @keyframes ani3 {
    33%{
      opacity: 0;
    }
    41%{
      opacity: 1;
    }
    49%{
      opacity: 0;
    }
  }
  
  @keyframes ani4 {
    49%{
      opacity: 0;
    }
    58%{
      opacity: 1;
    }
    66%{
      opacity: 0;
    }
  }
  
  @keyframes ani5 {
    66%{
      opacity: 0;
    }
    74%{
      opacity: 1;
    }
    82%{
      opacity: 0;
    }
  }
  
  @keyframes ani6 {
    82%{
      opacity: 0;
    }
    90%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  
  }


  .word-animation{
    position: relative;
  }
  
  .word{
    font-size: 180px;
    font-weight: 400;
    color: #000000;
    font-family: "Big Shoulders Text", sans-serif;
    opacity: 0;
    margin-top: -125px;
    text-transform: uppercase;
  }
  
  .w7{
    animation: ani7 18s infinite;
  }
  
  .w8{
    animation: ani8 18s infinite;
  }
  
  .w9{
    animation: ani9 18s infinite;
  }
  
  .w10{
    animation: ani10 18s infinite;
  }
  .w11{
    animation: ani11 18s infinite;
  }
  .w12{
    animation: ani12 18s infinite;
  }
  
  @keyframes ani7 {
    0%{
      opacity: 0;
    }
    8%{
      opacity: 1;
    }
    16%{
      opacity: 0;
    }
  }
  
  @keyframes ani8 {
    16%{
      opacity: 0;
    }
    25%{
      opacity: 1;
    }
    33%{
      opacity: 0;
    }
  }
  
  @keyframes ani9 {
    33%{
      opacity: 0;
    }
    41%{
      opacity: 1;
    }
    49%{
      opacity: 0;
    }
  }
  
  @keyframes ani10 {
    49%{
      opacity: 0;
    }
    58%{
      opacity: 1;
    }
    66%{
      opacity: 0;
    }
  }
  
  @keyframes ani11 {
    66%{
      opacity: 0;
    }
    74%{
      opacity: 1;
    }
    82%{
      opacity: 0;
    }
  }
  
  @keyframes ani12 {
    82%{
      opacity: 0;
    }
    90%{
      opacity: 1;
    }
    100%{
      opacity: 0;
    }
  
  }


  @media only screen and (max-width:349px) {
    .main-title__word, .main-title__wordYell, .word, .word-2 {
        font-size: 40px;
        line-height: 60px;
    }

    .main__para{
        font-size: 14px;
    }

    .visit__icon{
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
   
}

@media only screen and (max-width: 349px) {
  .main-title__wordYell{
    margin-top: 0px;
  }
  .word{
    margin-top: 0px;
  }
  .main__para{
    margin-top: 24px;
  }
  .main-title__word{
    display: block;
  }
  .word__containerr{
    width: 100%;
  }

}

@media (min-width: 350px) and (max-width: 400px) {
  .main-title__wordYell{
    margin-top: -30px;
    /* margin-left: 9px; */
  }
  .word{
    margin-top: -40px;
  }
  .main-title__word, .main-title__wordYell, .word , .word-2{
    font-size: 40px;
    line-height: 60px;
}

}

@media (min-width:351px) and (max-width:375px) {
  .word__containerr{
    margin-left: 8px;
  }
}

@media (min-width: 401px) and (max-width: 430px) {
  .main-title__word, .main-title__wordYell, .word , .word-2{
    font-size: 45px;
    line-height: 60px;
   }
   .main-title__wordYell{
     margin-top: -30px;
     margin-left: -9px;
   }
   .word{
     margin-top: -40px;
   }

}

@media (min-width: 431px) and (max-width: 480px) {
  .main-title__word, .main-title__wordYell, .word , .word-2{
    font-size: 50px;
    line-height: 60px;
   }
   .main-title__wordYell{
     margin-top: -30px;
     margin-left: -25px;
   }
   .word{
     margin-top: -40px;
   }

}

@media (min-width: 481px) and (max-width:500px) {
  .main-title__word, .main-title__wordYell, .word , .word-2{
    font-size: 50px;
    line-height: 60px;
   }
   .main-title__wordYell{
     margin-top: -30px;
     margin-left: -45px;
   }
   .word{
     margin-top: -40px;
   }

}

@media (min-width: 501px) and (max-width:535px) {
  .main-title__word, .main-title__wordYell, .word , .word-2{
    font-size: 50px;
    line-height: 60px;
   }
   .main-title__wordYell{
     margin-top: -30px;
     margin-left: -65px;
   }
   .word{
     margin-top: -40px;
   }

}

@media (min-width:536px) and (max-width:600px) {
  .main-title__wordYell{
    margin-top: -50px;
    margin-left: -15px;
  }
  .word{
    margin-top: -55px;
  }
  .main__para{
    margin-top: 20px;
  }
}

@media (min-width:536px) and (max-width: 576px) {
  .main-title__wordYell{
    margin-top: -50px;
    margin-left: -15px;
  }
  .word{
    margin-top: -55px;
  }
  .main__para{
    margin-top: 20px;
  }
}

@media (min-width:577px) and (max-width: 600px) {
  .main-title__wordYell{
    margin-top: -50px;
    margin-left: -28px;
  }
  .word{
    margin-top: -55px;
  }
  .main__para{
    margin-top: 20px;
  }
}

@media (min-width: 601px) and (max-width: 631px) {
  .main-title__wordYell{
    margin-top: -50px;
    margin-left: -40px;
  }
  .word{
    margin-top: -55px;
  }
  .main__para{
    margin-top: 50px;
  }
}


@media (min-width: 632px) and (max-width: 639px) {
  .main-title__word, .main-title__wordYell, .word, .word-2 {
    font-size: 70px;
    line-height: 120px;
}

}

@media (min-width: 640px) and (max-width: 767px) {
  .main-title__word, .main-title__wordYell, .word, .word-2 {
    font-size: 80px;
    line-height: 120px;
}
  .main-title__wordYell{
    margin-top: -60px;
    margin-left: -20px;
  }
  .word{
    margin-top: -55px;
  }
  .main__para{
    margin-top: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px){
  .main-title__wordYell{
    margin-top: -50px;
    margin-left: -40px;
  }
  .word{
    margin-top: -55px;
  }
}

@media (min-width: 1025px) and (max-width: 1279px){
  .main-title__wordYell{
    margin-top: -90px;
    margin-left: 40px;
  }
  .word{
    margin-top: -90px;
  }
}

@media (min-width: 1280px) and (max-width: 1535px){
  .main-title__wordYell{
    margin-top: -110px;
    margin-left: 40px;
  }
}




@media (min-width:350px) and (max-width: 535px) {
   
    .main__para{
        font-size: 14px;
    }

    .visit__icon{
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
  
    .main__title{
      display: flex;
    }
    .main__para{
      margin-top: 24px;
    }
}


@media (min-width: 536px) and (max-width: 639px){
    .main-title__word, .main-title__wordYell, .word, .word-2 {
        font-size: 70px;
        line-height: 100px;
    }

    .main__para{
        font-size: 14px;
    }

    .visit__icon{
        width: 30px;
        height: 30px;
        font-size: 16px;
    }

    .main__title{
      display: flex;
    }
} 


@media (min-width: 640px) and (max-width: 767px) {
    

    .main__para{
        font-size: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .main-title__word, .main-title__wordYell, .word, .word-2 {
        font-size: 90px;
        line-height: 100px;
    }

    .main__para{
        font-size: 14px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px){
    .main-title__word, .main-title__wordYell, .word, .word-2 {
        font-size: 120px;
    }

    .main__para{
        font-size: 14px;
    }
}

@media (min-width: 1280px) and (max-width: 1535px) {
    .main-title__word, .main-title__wordYell, .word, .word-2 {
        font-size: 150px;
    }
}


.chant-icon{
  width: 50px;
  height: 45px;
  border-radius: 4px;
  background-color: #FED21B;
  color: #000000;
  position: fixed;
  right: 20px;
  bottom: 40%;
  font-size: 28px;
  display: grid;
  place-content: center;
  z-index: 999;
}