@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.coreteam{
    background-color: #000000;
}

.coreteam__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;
}

.coreteam__img{
    width: 280px;
    height: 600px;
    /* background-image: url(../../../public/images/dhivagar.avif); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px; 
    position: relative;  
    overflow: hidden; 
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.coreteam__img.active{
    width: 450px;
}

/* 
.coreteam__img:hover{
    width: 450px;
    transition: all 0.5s ease-in-out;
} */

.coreteam__img.active .coreteam__name{
    display: block;
    top: 80%;
    transition: all 0.5s ease-in-out;
}

.coreteam__img.active .coreteam__social{
    opacity: 1;
    left: 90%;
    transition: all 0.5s ease-in-out;
}

.coreteam__social{
    position: absolute;
    top: 10%;
    left: 100%;
    color: #FED21B;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

.coreteam__name{
    background-color: #FFFFFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 120px;
    position: absolute;
    top: 100%;
    width: 100%;
    transition: all 0.5s ease-in-out;
    display: none;
}

.coreteam__header{
    font-size: 32px;
    font-weight: 500;
    color: #FED21B;
}

.coreteam__role{
    font-size: 24px;
    font-weight: 500;
    color: #000000;
}

/* .coreteam__img:nth-child(2){
    background-image: url(../../../public/images/jiyathL.jpg);
}

.coreteam__img:nth-child(3){
    background-image: url(../../../public/images/heera.jpg);
}

.coreteam__img:nth-child(4){
    background-image: url(../../../public/images/djv.avif);
}

.coreteam__img:nth-child(5){
    background-image: url(../../../public/images/benja.webp);
} */