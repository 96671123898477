.workflow{
    background-color: #000000;
}

.workflow__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;
}

.workFlow__startLine{
    height: 1.8px;
    /* background-color: #646464; */
    /* background-color: #FED21B; */
    width: 96px;
    display: inline-block;
    position: relative;
    border-top: 1px dashed #FED21B;
}

.workFlow__startLine::before{
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    /* background-color: #646464; */
    background-color: #FED21B;
    position: absolute;
    top: -7.8px;
    left: 0;
}

.workflowContent__icon{
    font-size: 36px;
    font-weight: 700;
    /* color: #ffffff; */
    color: #FED21B;
}

.workFlow__hoverContent:hover{
    transition: all .5s ease-in-out;
}

.workflowContent__icon2, .workflowContent__icon3, .workflowContent__icon4{
    font-size: 44px;
    font-weight: 700;
    color: #FED21B;
}

.workflowContent__title{
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    /* color: #FED21B; */
}

.workflow__content{
    width: 160px;
    position: relative;
}

.line{
    /* height: 1.8px; */
    background-color: #646464;
    width: 150px;
    display: inline-block;
    position: relative;
    border-top: 1px dashed #FED21B;
}

.workFlow__endLine{
    /* height: 1.8px; */
    width: 96px;
    display: inline-block;
    position: relative;
    border-top: 1px dashed #FED21B;

}

.workFlow__endLine::after{
    content: "";
    width: 14px;
    height: 14px;
    border-radius: 50%;
    /* background-color: #646464; */
    background-color: #FED21B;

    position: absolute;
    top: -7.8px;
    right: 0;
}

.workFlow__hoverContent{
    width: 280px;
    height: 280px;
    background-color: #FED21B;
    position: absolute;
    top: 10%;
    right: -128%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50%;
    transition: all .5s ease-in-out;
}

/* .workflow__box:hover .workFlow__hoverContent{
    opacity: 1;
} */

.workFlow__hoverContentTitle{
    font-size: 20px;
    font-weight: 600;
}

.workFlow__hoverContentPara{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

@media (min-width :1024px) and (max-width:1300px) {
    .workflow__content{
        width: 150px;
    }
    .line{
        width: 100px;
    }
    .workFlow__endLine, .workFlow__startLine{
        width: 56px;
    }
    .workflowContent__title{
        font-size: 18px;
    }
}

@media only screen and (max-width:1023px) {
    .workflow__title{
        font-size: 50px;
    }
    .workFlow__startLine, .workFlow__endLine{
        border-left: 1px dashed #FED21B;
        width: 1px;
        height: 56px;
    }
    .workFlow__startLine::before{
        left: -7.5px;
    }
    .line{
        border-left: 1px dashed #FED21B;
        width: 1px;
        height: 120px;
    }
    .workFlow__endLine::after{
        top: 100%;
        right: -7px;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .workFlow__hoverContent{
        width: 250px;
        height: 250px;
        right: -125%;
    }
    .workFlow__hoverContentTitle{
        font-size: 18px;
    }
    .workFlow__hoverContentPara{
        font-size: 13px;
    }
}