@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.about__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
}

.about__para{
    font-size: 18px;
    font-weight: 400;
    color: #555555;
    font-family: "Poppins", sans-serif;

}

.about__our-mission-title{
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    font-family: "Poppins", sans-serif;

}

.about__our-mission-para{
    font-size: 16px;
    font-weight: 400;
    color: #555555;
    border-top: 1px solid #000000;
    font-family: "Poppins", sans-serif;

}

@media only screen and (max-width:768px) {
    .about__title{
        font-size: 50px;
    }

    .about__para{
        font-size: 16px;
    }
}