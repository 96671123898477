@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.popup__container{
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    inset: 0;
    z-index: 999;
    display: grid;
    place-content: center;
}

.popup__content{
    width: 1057px;
    background: #fff;
}

.popup__Rightimg > img{
    width: 100%;
    height: 100%;
}

.popup__title {
    font-family: "Big Shoulders Text", sans-serif;
    font-size: 35px;
    font-weight: 600;
    color: #101828;
}

.popup__input > label, label{
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #344054;
    margin-bottom: 4px;
}

.popup__input > input{
    width: 100%;
    height: 35px;
    border: 1px solid #D0D5DD;
    outline: none;
    border-radius: 6px;
    padding: 0px 12px;
}

.popup__input textarea{
    border: 1px solid #D0D5DD;
    outline: none;
    border-radius: 6px;
    width: 100%;
    height: 70px;
    padding: 12px 12px;
}

.popupBtn > button{
    width: 100%;
    height: 40px;
    background-color: #000000;
    border: none;
    outline: none;
    color: #FED21B;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
}

.popup__checkBox > label{
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    margin-left: 8px;
}

.popupClose{
    width: 25px;
    height: 25px;
    background-color: #FED21B;
    color: #000000;
    font-size: 22px;
    display: grid;
    place-content: center;
    margin-left: auto;
    border-radius: 4px;
}

.popup__Formcontainer{
    width: 100%;
    height: 100%;
    padding: 26px 50px;
}

.error-msg{
    font-size: 12px;
    color: red;
}

@media only screen and (max-width: 1060px) {
    .popup__content{
        width: 100%;
        margin: 0px auto;
    }
}

@media only screen and (max-width:640px)  {
    .popup__Formcontainer{
        padding: 12px 18px;
    }
    .popup__content{
        max-height: 600px;
    }
    .popup__title{
        font-size: 28px;
    }
}