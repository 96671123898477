@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.community{
    position: relative;
    z-index: -1;
}

.community__title{
    font-size: 60px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;
}

.investor-btn button{
    width: 250px;
    height: 52px;
    background-color: #FED21B;
    color: #000000;
    font-weight: 600;
}

.freelancer-btn button{
    width: 250px;
    height: 52px;
    color: #000000;
    border: 2px solid #000000;
}

.community__img{
    height: 500px;
}

.community__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.community__clickBtn{
    clip-path: polygon(36% 10%, 64% 3%, 100% 4%, 100% 100%, 79% 93%, 50% 98%, 25% 95%, 0 100%, 3% 74%, 0 0);   
    background-color: #FED21B;
    width: 142px;
    height: 34px;
    font-size: 12px;
    font-weight: 700;
    color: #000000;
    border: 1px solid #000000;
}

@media only screen and (max-width:768px) {
    .community__title{
        font-size: 40px;
    }
    .community__img img{
        object-fit: cover;
    }
}

@media only screen and (max-width: 1535px) {
    .community{
        bottom: -60px;
    }
}
