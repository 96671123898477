@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.marquee{
    background-color: #000000;
}

.marquee-name{
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 2px;
    font-family: "Big Shoulders Text", sans-serif;
}

.marqueeYellow{
    color: #FED21B;
}

.vide__img{
    width: 100%;
    height: 717px;
}

.vide__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 639px) {
    .marquee-name{
        font-size: 14px;
    }
    .vide__img{
        height: 250px;
    }
    .client__title{
        font-size: 40px;
    }
}

@media (min-width:640px) and (max-width: 767px){
    .vide__img{
        height: 300px;
    }

}

@media (min-width: 768px) and (max-width: 1023px){
    .vide__img{
        height: 400px;
    }

}

@media (min-width: 1024px) and (max-width: 1279px){
    .vide__img{
        height: 500px;
    }

}

@media (min-width: 1280px) and (max-width: 1535px){
    .vide__img{
        height: 600px;
    }

}