@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.investor{
    background-image: url(../../../public/images/Bg.webp);
    height: 628px;
    background: url(../../../public/images/Bg.webp) cover/center no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.investor__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;
}

.investor__para{
    font-size: 20px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
}

.investor-para__yello{
    color: #FED21B;
}

.investor__btn  button{
    width: 200px;
    height: 60px;
    background-color: #FED21B;
    color: #000000;
    font-family: "Big Shoulders Text", sans-serif;
    font-size: 22px;
    font-weight: 700;
    border: none;
    outline: none;
}


.investor__container::after{
    content: "";
    position: absolute;
    background-image: url(../../../public/images/leftImg.webp);
    left: 0;
    bottom: 0;
    width: 40%;
    height: 350px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: cover/center;
}

.investor__container::before{
    content: "";
    position: absolute;
    background-image: url(../../../public/images/rightImg.webp);
    right: 0;
    top: 0;
    width: 38%;
    height: 600px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: cover/center;
}


@media only screen and (max-width:320px) {
    .investor{
        height: 338px;
        overflow: hidden;
    }
    .investor__title{
        font-size: 30px;
    }
    .investor__para{
        font-size: 14px;
    }
    .investor__btn  button{
        width: 120px;
        height: 32px;
        font-size: 14px;
    }
}

@media only screen and (max-width:600px) {
    .investor__container::after, .investor__container::before{
        display: none;
    }
}

@media (min-width:321px) and (max-width: 425px) {
    .investor{
        height: 318px;
        overflow: hidden;
    }
    .investor__title{
        font-size: 30px;
    }
    .investor__para{
        font-size: 14px;
    }
    .investor__btn  button{
        width: 120px;
        height: 32px;
        font-size: 14px;
    }
}

@media (min-width: 426px) and (max-width: 649px){
    .investor{
        height: 318px;
        overflow: hidden;
    }
    .investor__title{
        font-size: 30px;
    }
    .investor__para{
        font-size: 14px;
    }
    .investor__container::after{
        height: 100px;
        left: -60px;
    }
    .investor__container::before{
        height: 150px;
        top: 44%;
        width: 30%;
        right: -40px;
    }
    .investor__btn  button{
        width: 120px;
        height: 38px;
        font-size: 14px;
    }
}

@media (min-width: 640px) and (max-width: 767px){
    .investor{
        height: 348px;
        overflow: hidden;
    }
    .investor__title{
        font-size: 30px;
    }
    .investor__para{
        font-size: 14px;
    }
    .investor__container::after{
        height: 100px;
        left: -60px;
    }
    .investor__container::before{
        height: 150px;
        top: 44%;
        width: 30%;
        right: -40px;
    }
    .investor__btn  button{
        width: 120px;
        height: 38px;
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .investor{
        height: 388px;
        overflow: hidden;
    }
    .investor__title{
        font-size: 50px;
    }
    .investor__container::after{
        width: 37%;
        height: 110px;
        left: -60px;
    }
    .investor__container::before{
        height: 260px;
        width: 30%;
        right: -50px;
        top: 30%;
    }
    .investor__btn  button{
        width: 160px;
        height: 52px;
        font-size: 18px;
    }
}


@media (min-width: 1024px) and (max-width:1280px) {
    .investor{
        height: 438px;
        overflow: hidden;
    }
    .investor__title{
        font-size: 60px;
    }
    .investor__container::after{
        height: 220px;
        left: -80px;
    }
    .investor__container::before{
        height: 370px;
        width: 30%;
        right: -60px;
    }
}

@media (min-width: 1279px) and (max-width:1440px) {
    .investor{
        height: 438px;
    }
    .investor__title{
        font-size: 60px;
    }
    .investor__container::after{
        height: 220px;
    }
    .investor__container::before{
        width: 30%;
        height: 400px;
    }
}

@media (min-width: 1441px) and (max-width:1535px) {
    .investor{
        height: 438px;
    }
    .investor__title{
        font-size: 60px;
    }
    .investor__container::after{
        height: 220px;
    }
    .investor__container::before{
        width: 30%;
        height: 400px;
    }
}

