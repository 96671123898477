@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.footer{
    background-color: #000000;
    position: relative;
    z-index: 1;
}

.footer__title{
    font-size: 22px;
    font-weight: 700;
    color: #FFFFFF;
}

.footer__para{
    font-size: 17px;
    font-weight: 400;
    color: #FFFFFF99;
}

.footer__social{
    color: #FFFFFF;
}

.footer__link p{
    font-size: 17px;
    font-weight: 400;
    color: #FFFFFF99;
    transition: all .5s ease-in-out;
}

.footer__link p:hover{
    transform: translateX(20px);
    color: #FBBC05;
}

.footer__socialIcon{
    width: 40px;
    height: 40px;
    border: 1px solid #FFFFFF33;
    display: grid;
    place-content: center;
    margin-right: 12px;
    border-radius: 8px;
}

.__socialIconLink{
    font-size: 20px;
    color: #fff;
}

.footer__from{
    width: 100%;
    height: 72px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #D5D5D7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px;

}

.footer__from input{
    border: none;
    outline: none;
}

.footer__from button{
    width: 140px;
    height: 52px;
    border: none;
    outline: none;
    background-color: #FBBC05;
    color: #000000E5;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
}

.footer__copyRights{
    font: 12px;
    font-weight: 400;
    color: #FFFFFFCC;
}

.footer__content{
    width: 25%;
}

.footer__containerLink{
    width: 15%;
}

.footer__socialIcon:hover{
    background-color: #FBBC05;
}
.footer__socialIcon:hover .__socialIconLink{
    color: #000000;
}

@media only screen and (max-width:639px) {
    .footer__from{
        height: 52px;
        width: 90%;
    }

    .footer__from button{
        width: 100px;
        height: 38px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 768px) {
    .footer__content, .footer__containerLink{
        width: 100%;
    }
    .footer__socialIcon{
        background-color: #FBBC05;
    }
    .__socialIconLink{
        color:#000000;
    }

}

@media (min-width: 769px) and (max-width: 1023px) {
    .footer__content{
        width: 35%;
    }
    .footer__containerLink{
        width: 25%;
    }
}

