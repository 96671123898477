@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.solution__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
}

.solution__box{
    height: 450px;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.solution__box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.solution__content-title{
    font-size: 24px;
    font-weight: 500;
    color: #FED21B;
    text-transform: uppercase;
}

.solution__content{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 82%;
    padding: 20px 20px 40px;
    /* background-color: rgba(0, 0, 0, 0.1); */
    transition: all 0.5s ease-in-out;
    z-index: 3;
}

.solution__box::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    inset: 0;
    z-index: 2;
}

.solution__box:hover .solution__content{
    top: 0;
    transition: all 0.5s ease-in-out;
}

.solution__para{
    color: #fff;
    font-size: 18px;
    font-weight: 400;
}



@media only screen and (max-width:375px) {
    .solution__box{
        width: 300px;
        height: 300px;
    }

    .solution__content{
        top: 80%;
    }

    .solution__content-title{
        font-size: 20px;
    }

    .solution__title{
        font-size: 50px;
    }
}

@media (min-width:376px) and (max-width: 768px) {
    .solution__box{
        width: 350px;
        height: 350px;
    }
    .solution__content{
        top: 80%;
    }

    .solution__content-title{
        font-size: 24px;
    }

    .solution__title{
        font-size: 50px;
    }
}

@media (min-width:769px) and (max-width:1023px) {
    .solution__box{
        height: 350px;
    }
  
}

@media (min-width:1024px) and (max-width:1279px) {
    .solution__content-title{
        font-size: 24px;
    }
    .solution__content{
        top: 86%;
    }
    
}