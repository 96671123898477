@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.service{
    background-color: #000000;
}

.service__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    border-bottom: 1px solid #ffffff;
    width: 48%;
}



.service__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    transition: all 0.5s ease-in-out;
}

.service__img{
    transition: all 0.5s ease-in-out;
}


.service__no{
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #fff;
    font-size: 60px;
    font-weight: 400;
    font-family: "Big Shoulders Text", sans-serif;
    color: #000000;
}

.service__heading{
    font-size: 60px;
    font-weight: 400;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;

}

.service__name{
    border-bottom: 1px solid #ffffff;
    padding: 4px 0px;
    cursor: pointer;
    color: #ffffff;
}


.service__name:hover{
    color: #FED21B;
}

.service__name:hover .service__no{
    color: #FED21B;
    -webkit-text-stroke-color: #FED21B;
}

.service-phoneView__name{
    color: #FED21B;
    border-bottom: 1px solid #ffffff;
    padding: 4px 0px;
    cursor: pointer;
}

.service-phoneView__name > .service__no{
    -webkit-text-stroke-color: #FED21B;
    color: #FED21B;
}

.service-phoneView__img{
    height: 300px;
    width: 100%;
}

.service-phoneView__img img{
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 639px) {
    .service-phoneView__img{
        height: 200px;
    }

    .service__title{
        font-size: 50px;
    }
}

@media only screen and (max-width:1023px) {
    .service__title{
        width: 100%;
    }
}

@media only screen and  (max-width: 515px) {
    .service__no, .service__heading{
        font-size: 28px;
    }
}

@media (min-width:516px) and (max-width: 768px) {
    .service__no, .service__heading{
        font-size: 44px;
    }
}

@media (min-width: 1023px) and (max-width:1279px) {
    .service__no, .service__heading{
        font-size: 38px;
    }
}

@media (min-width: 1280px) and (max-width:1535px) {
    .service__no, .service__heading{
        font-size: 48px;
    }
}