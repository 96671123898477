@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Text:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.client__title{
    font-size: 70px;
    font-weight: 400;
    color: #FED21B;
    font-family: "Big Shoulders Text", sans-serif;
    text-transform: uppercase;
}

.client__img{
    width: 175px;
    height: 77px;
}

.client__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width:768px) {
    .client__title{
        font-size: 50px;
    }
}