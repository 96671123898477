@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  scroll-behavior: smooth;
  scrollbar-width: 20px;
  scrollbar-color: #FED21B;
  cursor: pointer;
}

.cursor{
  width: 40px;
  height: 40px;
  border: 2px solid #FED21B;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 50%;
  z-index: 9999;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: 1s ;
}

.cursor2{
  width: 8px;
  height: 8px;
  border: 2px solid #FED21B;
  position: fixed;
  left: 0;
  top: 0;
  border-radius: 50%;
  z-index: 9999;
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.main__para{
  font-size: 17px;
  font-weight: 400;
  color: #555555;
}

.main__para:hover  .cursor{
  transform: translate(-50%, -50%) scale(1.5);
  background-color: #FED21B;
  opacity: .5;

}

.favIcon{
  width: 42px;
  height: 42px;
  background-color: #000;
  animation: clr infinite ease-in-out;
}

@keyframes clr {
  0%{
    background-color: #000;
  }
  20%{
    background-color: beige;
  }
  60%{
    background-color: #FED21B;
  }
  100%{
    background-color: aqua;
  }
}