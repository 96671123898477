.swiper {
    width: 100%;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slick-slide > div{
    margin: 0px 10px;
}

.slick-next, .slick-prev{
    background-color: red;
}

@media only screen and (max-width: 425px) {
    .swiper{
        height: 200px;
    }
    .slick-next{
        right: -20px;
    } 
    .slick-prev{
        left: -20px;
    }
    .riva-ourProduct__title{
        font-size: 18px;
    }
    .riva-ourProduct__list {
        font-size: 14px;
    }
    .arrow-prod{
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .rivaProduct-slider{
        padding: 0px 40px;
    }
}

@media (min-width:481px) and (max-width: 640px) {
    .rivaProduct-slider{
        padding: 0px 20px;
    }
}

@media (min-width:426px) and (max-width:640px) {
    .swiper{
        height: 250px;
    }
    .riva-ourProduct__title{
        font-size: 18px;
    }
    .riva-ourProduct__list {
        font-size: 14px;
    }
    .arrow-prod{
        font-size: 16px;
    }

}

@media (min-width:641px) and (max-width:768px) {
    .swiper{
        height: 300px;
    }
    .rivaProduct-slider{
        padding: 0px 20px;
    }
    .slick-next{
        right: -20px;
    } 
    .slick-prev{
        left: -20px;
    }
    .riva-ourProduct__title{
        font-size: 22px;
    }
    .riva-ourProduct__list {
        font-size: 16px;
    }
    .arrow-prod{
        font-size: 20px;
    
    }
}

@media (min-width:769px) and (max-width:880px) {
    .slick-next{
        right: -20px;
    } 
    .slick-prev{
        left: -20px;
    }
}

@media (min-width:881px) and (max-width:1023px) {
    .slick-next{
        right: -40px;
    } 
    .slick-prev{
        left: -40px;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .swiper{
        height: 400px;
    }
    
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .swiper{
        height: 450px;
    }
}

@media (min-width: 1281px) and (max-width: 1535px) {
    .swiper{
        height: 500px;
    }
}


/****** About style ******/

.riva-about__title{
    color: #3a3a3a;
    font-size: 32px;
    font-weight: 600;
}

.riva-about__para{
    color: #3A3A3A;
    font-size: 18px;
    font-weight: 400;
}

.riva-about__container{
    width: 1300px;
}

@media only screen and (max-width :640px) {
    .riva-about__title{
        font-size: 24px;
    }
    .riva-about__para{
        font-size: 14px;
    }
}

@media (min-width: 641px) and (max-width : 768px) {
    .riva-about__title{
        font-size: 28px;
    }
    .riva-about__para{
        font-size: 16px;
    }
}

@media only screen and (max-width: 1301px) {
    .riva-about__container{
        width: 100%;
    }
}