.word__text{
  font-size: 180px;
  font-weight: 400;
  color: #FED21B;
  font-family: "Big Shoulders Text", sans-serif;
}

.word__containerr{
   height: 270px;
   overflow: hidden;
   display: inline-block;
}

.word__containerr div:first-child{
  animation: text-animation 18s infinite;
}

@keyframes text-animation {
  0%{
    margin-top: 0;
  }
  10%{
    margin-top: -0.42rem;
  }
  20%{
    margin-top: -16.84rem;
  }
  40%{
    margin-top: -33.86rem;
  }
  60%{
    margin-top: -50.42rem;
  }
  80%{
    margin-top: -67.60rem;
  }
  100%{
    margin-top: -84.22rem;
  }
}

.word__textt{
  font-size: 180px;
  font-weight: 400;
  color: #000000;
  font-family: "Big Shoulders Text", sans-serif;
}

.word__containerrs{
   height: 250px;
   overflow: hidden;
   display: inline-block;
}

.word__containerrs div:first-child{
  animation: textTop-animation 18s infinite;
}

@keyframes textTop-animation {
  0%{
    margin-top: 0;
  }
  10%{
    margin-top: -0.62rem;
  }
  20%{
    margin-top: -16.84rem;
  }
  40%{
    margin-top: -33.86rem;
  }
  60%{
    margin-top: -50.42rem;
  }
  80%{
    margin-top: -67.60rem;
  }
  100%{
    margin-top: -84.22rem;
  }
}

@media only screen and (max-width: 400px) {
  .word__containerr{
    height: 60px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 50px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 40px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.12rem;
    }
    20%{
      margin-top: -3.84rem;
    }
    40%{
      margin-top: -7.56rem;
    }
    60%{
      margin-top: -11.32rem;
    }
    80%{
      margin-top: -15.10rem;
    }
    100%{
      margin-top: -18.82rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.12rem;
    }
    20%{
      margin-top: -3.84rem;
    }
    40%{
      margin-top: -7.56rem;
    }
    60%{
      margin-top: -11.32rem;
    }
    80%{
      margin-top: -15.10rem;
    }
    100%{
      margin-top: -18.82rem;
    }
  }

}

@media (min-width:401px) and (max-width: 430px) {
  .word__containerr{
    height: 60px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 50px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 45px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.12rem;
    }
    20%{
      margin-top: -4.34rem;
    }
    40%{
      margin-top: -8.56rem;
    }
    60%{
      margin-top: -12.92rem;
    }
    80%{
      margin-top: -17.10rem;
    }
    100%{
      margin-top: -21.32rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.12rem;
    }
    20%{
      margin-top: -4.34rem;
    }
    40%{
      margin-top: -8.56rem;
    }
    60%{
      margin-top: -12.92rem;
    }
    80%{
      margin-top: -17.10rem;
    }
    100%{
      margin-top: -21.32rem;
    }
  }

}

@media (min-width:431px) and (max-width: 535px) {
  .word__containerr{
    height: 60px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 60px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 50px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.61rem;
    }
    20%{
      margin-top: -4.94rem;
    }
    40%{
      margin-top: -10.01rem;
    }
    60%{
      margin-top: -14.32rem;
    }
    80%{
      margin-top: -19.10rem;
    }
    100%{
      margin-top: -23.92rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.61rem;
    }
    20%{
      margin-top: -4.94rem;
    }
    40%{
      margin-top: -10.01rem;
    }
    60%{
      margin-top: -14.32rem;
    }
    80%{
      margin-top: -19.10rem;
    }
    100%{
      margin-top: -23.92rem;
    }
  }

}

@media (min-width: 536px) and (max-width: 639px) {
  .word__containerr{
    height: 80px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 90px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 70px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.68rem;
    }
    20%{
      margin-top: -7.24rem;
    }
    40%{
      margin-top: -13.79rem;
    }
    60%{
      margin-top: -20.32rem;
    }
    80%{
      margin-top: -26.90rem;
    }
    100%{
      margin-top: -33.62rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.68rem;
    }
    20%{
      margin-top: -7.24rem;
    }
    40%{
      margin-top: -13.79rem;
    }
    60%{
      margin-top: -20.32rem;
    }
    80%{
      margin-top: -26.90rem;
    }
    100%{
      margin-top: -33.62rem;
    }
  }

}

@media (min-width: 640px) and (max-width: 767px) {
  .word__containerr{
    height: 90px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 100px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 80px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.68rem;
    }
    20%{
      margin-top: -8.24rem;
    }
    40%{
      margin-top: -16.09rem;
    }
    60%{
      margin-top: -23.32rem;
    }
    80%{
      margin-top: -30.90rem;
    }
    100%{
      margin-top: -38.62rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.68rem;
    }
    20%{
      margin-top: -8.24rem;
    }
    40%{
      margin-top: -16.09rem;
    }
    60%{
      margin-top: -23.32rem;
    }
    80%{
      margin-top: -30.90rem;
    }
    100%{
      margin-top: -38.62rem;
    }
  }

}

@media (min-width: 768px) and (max-width: 1023px) {
  .word__containerr{
    height: 110px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 110px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 90px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.68rem;
    }
    20%{
      margin-top: -9.04rem;
    }
    40%{
      margin-top: -17.49rem;
    }
    60%{
      margin-top: -26.02rem;
    }
    80%{
      margin-top: -34.60rem;
    }
    100%{
      margin-top: -42.92rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -.68rem;
    }
    20%{
      margin-top: -9.04rem;
    }
    40%{
      margin-top: -17.49rem;
    }
    60%{
      margin-top: -26.02rem;
    }
    80%{
      margin-top: -34.60rem;
    }
    100%{
      margin-top: -42.92rem;
    }
  }

}

@media (min-width: 1024px) and (max-width: 1279px) {
  .word__containerr{
    height: 120px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 130px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 120px;
  }

  .word__containerr div:first-child{
    animation: text-animation 15s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -1.68rem;
    }
    20%{
      margin-top: -13.04rem;
    }
    40%{
      margin-top: -24.49rem;
    }
    60%{
      margin-top: -35.52rem;
    }
    80%{
      margin-top: -46.70rem;
    }
    100%{
      margin-top: -58.12rem;
    }
  }

  .word__containerrs div:first-child{
    animation: textTop-animation 15s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -1.68rem;
    }
    20%{
      margin-top: -13.04rem;
    }
    40%{
      margin-top: -24.49rem;
    }
    60%{
      margin-top: -35.52rem;
    }
    80%{
      margin-top: -46.70rem;
    }
    100%{
      margin-top: -58.12rem;
    }
  }

}

@media (min-width: 1280px) and (max-width: 1535px) {
  .word__containerr{
    height: 160px;
    overflow: hidden;
    display: inline-block;
  }

  .word__containerrs{
    height: 170px;
    overflow: hidden;
    display: inline-block;
  }
  
  .word__text, .word__textt{
    font-size: 150px;
  }

  .word__containerr div:first-child{
    animation: text-animation 18s infinite;
  }

  @keyframes text-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -1.98rem;
    }
    20%{
      margin-top: -16.24rem;
    }
    40%{
      margin-top: -30.49rem;
    }
    60%{
      margin-top: -44.22rem;
    }
    80%{
      margin-top: -58.40rem;
    }
    100%{
      margin-top: -72.12rem;
    }
  }
  

  .word__containerrs div:first-child{
    animation: textTop-animation 18s infinite;
  }
  
  @keyframes textTop-animation {
    0%{
      margin-top: 0;
    }
    10%{
      margin-top: -1.98rem;
    }
    20%{
      margin-top: -16.24rem;
    }
    40%{
      margin-top: -30.49rem;
    }
    60%{
      margin-top: -44.22rem;
    }
    80%{
      margin-top: -58.40rem;
    }
    100%{
      margin-top: -72.12rem;
    }
  }

}