.header__{
    background-color: #fff;
    height: 112px;
    position: fixed;
    z-index: 999;
}

.header__container{
    height: 100%;
    border-bottom: 1px solid #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.header__logo{
    width: 200px;
    height: 80px;
    object-fit: contain;
    margin-left: -40px;
}

.brandletScroll__logo{
    width: 200px;
    height: 80px;
    object-fit: contain;
}


.header__container nav ul{
    display: flex;
    align-items: center;
    column-gap: 48px;
    transition: all 0.5s ease-in-out;
}

.header__btn  button{
    width: 150px;
    height: 46px;
    border: 2px solid #000000;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    position: relative;
    /* transition: all .5s ease-in-out; */
}

.header__btn  button::after{
    content: "";
    position: absolute;
    background-color: #000000;
    width: 0%;
    height: 100%;
    inset: 0;
    transition: all 0.5s ease-in-out;
}

.header__btn  button:hover::after{
   width: 100%;
   transition: all .5s ease-in-out;
   z-index: -1;
}

.header__btn  button:hover{
    color: #FED21B;
    z-index: 1;
    transition: all .5s ease-in-out;
}

.header__container nav ul li {
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #000000;
    position: relative;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
}

.header__container nav ul li::before{
    content: ""; 
    position: absolute;
    pointer-events: none;
    width: 0%; 
    height: 3px;
    background-color: #FED21B;
    box-shadow: 0px 0px 8px #FED21B;
    border-radius: 8px;
    top: 110%;
    transition: all 0.5s ease-in-out;
}

.header__container nav ul li:hover::before {
    content: ""; 
    position: absolute;
    pointer-events: none;
    width: 70%;
    transform: translateX(50%);
    transition: all 0.5s ease-in-out;
}

.header__logo img {
    transition: all 0.5s ease-in-out;
}

.header__logo img.scrolled {
    transition: all 0.5s ease-in-out;
}


@media (min-width:1024px) and (max-width: 1280px) {
    .header__btn  button{
        width: 180px;
        font-size: 20px;
        height: 52px;

    }

    .header__container nav ul li{
        column-gap: 20px;
        font-size: 15px;
    }
}

@media only screen and (max-width: 425px) {

    .header__{
       height: fit-content;
       padding: 0px 20px;
    }

    .header__btn  button{
        width: 70px;
        height: 24px;
        font-size: 10px;
        font-weight: 500;

    } 

    .header__logo{
        width: 100px;
        height: 56px;
        margin-left: -16px;
    }

    .brandletScroll__logo{
        width: 100px;
        height: 50px;
    }

   
}

@media (min-width:426px) and (max-width: 639px) {
    .header__{
        height: fit-content;
        padding: 0px 20px;
     }
 
     .header__btn  button{
         width: 120px;
         height: 32px;
         font-size: 14px;
     } 
 
     .header__logo{
        width: 100px;
        height: 56px;
        margin-left: -16px;
    }

    .brandletScroll__logo{
        width: 100px;
        height: 50px;
    }

     .header__container{
        justify-content: normal;
        align-items: center;
     }
}

@media (min-width:640px) and (max-width: 767px){
    .header__btn  button{
        width: 120px;
        height: 42px;
        font-size: 14px;
    } 

    .header__{
        height: 90px;
    }

    .header__logo{
        width: 100px;
        height: 56px;
        margin-left: -10px;
    }

    .brandletScroll__logo{
        width: 100px;
        height: 50px;
    }
}

@media (min-width: 768px) and (max-width: 1023px){
    .header__btn  button{
        width: 120px;
        height: 42px;
        font-size: 14px;
    } 
}

@media (min-width: 1024px) and (max-width: 1279px){
    .header__container nav ul{
        column-gap: 30px;
    }

    .header__btn  button{
        width: 120px;
        height: 42px;
        font-size: 14px;
    } 
}